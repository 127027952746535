@import url('https://fonts.googleapis.com/css?family=Muli');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

/*@import url('https://maxcdn.bootstrapcdn.com/font-awesome/4.3.0/css/font-awesome.min.css');*/

/* Box sizing rules */
*,
*::before,
*::after {
    box-sizing: border-box;
}

/* Remove default padding */
ul[class],
ol[class] {
    padding: 0;
}

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
p,
ul[class],
ol[class],
li,
figure,
figcaption,
blockquote,
dl,
dd {
    margin: 0;
}

/* Set core body defaults */
body {
    min-height: 100vh;
    scroll-behavior: smooth;
    text-rendering: optimizeSpeed;
    line-height: 1.5;
}

/* Remove list styles on ul, ol elements with a class attribute */
ul[class],
ol[class] {
    list-style: none;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
    text-decoration-skip-ink: auto;
}

/* Make images easier to work with */
img {
    width: 100%;
    display: block;
}
.integrar-plataformas img{
    width: auto;
}
/* Natural flow and rhythm in articles by default */
article > * + * {
    margin-top: 1em;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
    font: inherit;
}

/* Remove all animations and transitions for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
    * {
        animation-duration: 0.01ms !important;
        animation-iteration-count: 1 !important;
        transition-duration: 0.01ms !important;
        scroll-behavior: auto !important;
    }
}

body {
    background-color: #ffffff;
    font-family: Muli;
    font-style: normal;
    font-weight: normal;
    /*font-size: 14px;*/
    /*line-height: 3vh;*/
    color: #424147;
}

.class-header {
    position: absolute;
    z-index: 99;
    text-align: left;
    right: 60px;
}
.banner > div {
    padding-left: 10%;
    padding-top: 2rem;
    padding-bottom: 2rem;
}
.titulo1 {
    font-weight: bold;
    color: #143658;
}
.titulo2 {
    font-weight: bold;
    color: #009fe3;
}
.text-azul {
    color: #009fe3;
}
.hr-short {
    height: 0.4rem !important;
    width: 30%;
    color: #14264b;
    border-color: #14264b;
    opacity: 0.8;
}
.hr-medium {
    height: 0.2rem !important;
    color: #14264b;
    border-color: #14264b;
    opacity: 0.8;
    width: 50%;
}
.div-suscribete {
    position: relative;
}
.btn-suscribete {
    font-size: 1.2vw;
    border-radius: 20px;
    bottom: 0px;
    position: absolute;
}
.btn-suscribete-ahora,
.btn-suscribete-ahora:hover {
    background-color: #189ad6;
    color: white;
    width: 100%;
    border-radius: 20px;
    bottom: 0px;
}
.image-app {
    height: 550px;
    width: 350px;
    display: block;
    margin: auto;
}
.header-div {
    background-color: #143658;
    height: 70px;
}
.footer {
    background-color: #16375b;
    color: white;
}
.footer label {
    padding-left: 25px;
}
.img-canjes {
    width: 300px;
    height: 300px;
    display: block;
    margin: auto;
}
.elemento-center {
    display: block;
    margin: auto;
}
.carousel-caption {
    top: 7vw;
    left: 50%;
}
/*.carousel-caption img{
  width: 41vw;
}*/
.class-header img {
    width: 100%;
    height: 3.5rem;
    margin-top: 0.5rem;
}
.bannerSlider {
    margin-top: 5vw;
}

.bannerSlider img:first-child {
    width: 15vw;
    margin-top: 0.4vw;
    position: absolute;
    left: 19vw;
}
.productCarrousel {
    background-color: #f2f2f2;
    border-radius: 15px;
    box-shadow: 0px 0px 10px 1px black;
    text-align: center;
}

.productCarrousel img {
    width: auto;
    height: 25vw;
    display: initial;
}

.carousel-content > div:nth-child(3) img {
    width: 17vw;
    height: 17vw;
}
.carousel-content > div:nth-child(2) img {
    width: 30vw;
    height: 30vw;
}
.carousel-content > div:nth-child(1) img {
    width: 17vw;
    height: 17vw;
}

.carousel-content > div:nth-child(3) {
    z-index: 0;
}
.carousel-content > div:nth-child(2) {
    z-index: 1;
}
.carousel-content > div:nth-child(1) {
    z-index: 0;
}
.carousel-content > div:nth-child(3) .productCarrousel {
    width: 60%;
    left: 0;
    right: auto;
    float: left;
    margin-top: 2vw;
    height: 85%;
}
.carousel-content > div:nth-child(2) .productCarrousel {
    width: 100%;
    right: auto;
    left: auto;
    float: initial;
    margin-top: 1%;
    height: 98%;
}
.carousel-content > div:nth-child(1) .productCarrousel {
    width: 60%;
    right: 0;
    left: auto;
    float: right;
    margin-top: 2vw;
    height: 85%;
}

.logoIzqProductos {
    width: 10vw;
    height: 10vw;
    position: absolute;
    bottom: 0px;
    left: 70px;
}
.logoDerProductos {
    width: 10vw;
    height: 10vw;
    position: absolute;
    bottom: 0px;
    right: 70px;
}

/*anexado de figma*/

.referidos {
    height: 1080px;
    width: 1920px;
    background-color: #ffffff;
}
.background {
    height: 1080px;
    width: 1920px;
    background-color: #f8f7fc;
}
.rectangle-1-8 {
    border-radius: 0.25rem;
    height: 743px;
    width: 486px;
    background-color: #ffffff;
}
.rectangle-1-9 {
    border-radius: 0.25rem;
    height: 743px;
    width: 486px;
    background-color: #ffffff;
}
.text-1 {
    text-align: right;
    vertical-align: top;
    font-size: 20px;
    font-family: Montserrat;
    line-height: 69.9999988079071%;
    color: #0b1f44;
}
.text-2 {
    text-align: left;
    vertical-align: top;
    font-size: 30px;
    font-family: Montserrat;
    line-height: 89.99999761581421%;
    color: #0b1f44;
}
.text-3 {
    text-align: left;
    vertical-align: top;
    font-size: 15px;
    font-family: Montserrat;
    line-height: auto;
    color: #44c2fa;
}
.text-4 {
    text-align: left;
    vertical-align: top;
    font-size: 24px;
    font-family: Montserrat;
    line-height: auto;
    color: #0b1f44;
}
.text-5 {
    text-align: center;
    vertical-align: top;
    font-size: 24px;
    font-family: Montserrat;
    line-height: auto;
    color: #0b1f44;
}
.text-6 {
    text-align: center;
    vertical-align: top;
    font-size: 70px;
    font-family: Montserrat;
    line-height: auto;
    color: #2ed28a;
}
.text-7 {
    text-align: center;
    vertical-align: top;
    font-size: 70px;
    font-family: Montserrat;
    line-height: auto;
    color: #2ed28a;
}
.text-8 {
    text-align: left;
    vertical-align: top;
    font-size: 20px;
    font-family: Montserrat;
    line-height: auto;
    color: #526c9a;
}
.text-9 {
    text-align: center;
    vertical-align: top;
    font-size: 20px;
    font-family: Montserrat;
    line-height: auto;
    color: #526c9a;
}
.text-1-0 {
    text-align: left;
    vertical-align: top;
    font-size: 20px;
    font-family: Montserrat;
    line-height: auto;
    color: #0b1f44;
}
.text-1-1 {
    text-align: left;
    vertical-align: top;
    font-size: 30px;
    font-family: Montserrat;
    line-height: auto;
    color: #0b1f44;
}
.search {
    height: 50px;
    width: 434px;
}
.rectangle-1-6 {
    border-radius: 1.5625rem;
    height: 50px;
    width: 434px;
    background-color: #efedf6;
}
.rectangle-1-7 {
    border-radius: 1.5625rem;
    height: 50px;
    width: 212px;
    background-color: #fa5720;
}
.text-1-2 {
    text-align: center;
    vertical-align: top;
    font-size: 20px;
    font-family: Montserrat;
    line-height: 69.9999988079071%;
    color: #ffffff;
}
.text-1-3 {
    text-align: left;
    vertical-align: top;
    font-size: 20px;
    font-family: Montserrat;
    line-height: 69.9999988079071%;
    color: #0b1f44;
}
.search {
    height: 50px;
    width: 221px;
}
.rectangle-1-6 {
    border-radius: 1.5625rem;
    height: 50px;
    width: 214px;
    background-color: #efedf6;
}
.rectangle-1-7 {
    border-radius: 1.5625rem;
    height: 50px;
    width: 108px;
    background-color: #fa5720;
}
.text-1-4 {
    text-align: center;
    vertical-align: top;
    font-size: 20px;
    font-family: Montserrat;
    line-height: 69.9999988079071%;
    color: #ffffff;
}
.text-1-5 {
    text-align: center;
    vertical-align: top;
    font-size: 20px;
    font-family: Montserrat;
    line-height: 69.9999988079071%;
    color: #0b1f44;
}
.rectangle-2-1 {
    border-radius: 0.25rem;
    height: 743px;
    width: 486px;
    background-color: #ffffff;
}
.text-1-6 {
    text-align: right;
    vertical-align: top;
    font-size: 20px;
    font-family: Montserrat;
    line-height: 69.9999988079071%;
    color: #0b1f44;
}
.text-1-7 {
    text-align: center;
    vertical-align: top;
    font-size: 24px;
    font-family: Montserrat;
    line-height: auto;
    color: #0b1f44;
}
.text-1-8 {
    text-align: center;
    vertical-align: top;
    font-size: 70px;
    font-family: Montserrat;
    line-height: auto;
    color: #2ed28a;
}
.text-1-9 {
    text-align: center;
    vertical-align: top;
    font-size: 70px;
    font-family: Montserrat;
    line-height: auto;
    color: #2ed28a;
}
.text-2-0 {
    text-align: center;
    vertical-align: top;
    font-size: 20px;
    font-family: Montserrat;
    line-height: auto;
    color: #526c9a;
}
.text-2-1 {
    text-align: left;
    vertical-align: top;
    font-size: 20px;
    font-family: Montserrat;
    line-height: auto;
    color: #0b1f44;
}
.text-2-2 {
    text-align: left;
    vertical-align: top;
    font-size: 30px;
    font-family: Montserrat;
    line-height: auto;
    color: #0b1f44;
}
.search {
    height: 50px;
    width: 221px;
}
.rectangle-1-6 {
    border-radius: 1.5625rem;
    height: 50px;
    width: 214px;
    background-color: #efedf6;
}
.rectangle-1-7 {
    border-radius: 1.5625rem;
    height: 50px;
    width: 108px;
    background-color: #2ed28a;
}
.text-2-3 {
    text-align: center;
    vertical-align: top;
    font-size: 20px;
    font-family: Montserrat;
    line-height: 69.9999988079071%;
    color: #0b1f44;
}
.text-2-4 {
    text-align: center;
    vertical-align: top;
    font-size: 20px;
    font-family: Montserrat;
    line-height: 69.9999988079071%;
    color: #ffffff;
}
.h-e-a-d-e-r {
    height: 73px;
    width: 1604px;
}
.rectangle-2 {
    height: 73px;
    width: 1604px;
    background-color: #ffffff;
}
.text-2-5 {
    text-align: left;
    vertical-align: top;
    font-size: 24px;
    font-family: Montserrat;
    line-height: auto;
    color: #c4c4c4;
}
.mask-group {
    height: 33px;
    width: 33px;
}
.ellipse-1 {
    height: 33px;
    width: 33px;
}
.img-2-6 {
    height: 32px;
    width: 49px;
}
.text-2-7 {
    text-align: left;
    vertical-align: bottom;
    font-size: 15px;
    font-family: Montserrat;
    line-height: auto;
    color: #0b1f44;
}
.menu {
    height: 1080px;
    width: 316px;
}
.rectangle-1 {
    border-radius: 0rem 0rem 0.25rem 0rem;
    height: 1080px;
    width: 316px;
    background-color: #0b1f44;
}
.text-2-8 {
    text-align: left;
    vertical-align: top;
    font-size: 20px;
    font-family: Montserrat;
    line-height: auto;
}
.img-2-9 {
    height: 20px;
    width: 21px;
}
.img-3-0 {
    height: 25px;
    width: 23px;
}
.img-3-1 {
    height: 20px;
    width: 20px;
}

@font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: normal;
    src: url('assets/fonts/inter/Inter-Regular.ttf');
}

@font-face {
    font-family: 'Inter-SemiBold';
    src: url('assets/fonts/inter/Inter-SemiBold.ttf');
}
@font-face {
    font-family: 'Inter-Bold';
    src: url('assets/fonts/inter/Inter-Bold.ttf');
}
@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: normal;
    src: url('assets/fonts/Poppins-Regular.ttf');
}
label,
a,
p,
h1,
h2,
h3,
h4,
h5,
h6,
input {
    font-family: 'Inter';
}
body {
    /*font-family: 'Inter';*/
}
.label-bold {
    font-family: 'Inter-Bold';
}
.content {
    background: #ffffff;
}
.form-select,
.form-control,
button,
.btn {
    border-radius: 4px;
    font-weight: 400;
}
.div-content {
    margin-top: 1rem;
    margin-bottom: 1rem;
}
.btn-write {
    background: white;

    background: white;
    border: none;
}
tbody {
    color: #526c9a;
}
#programa_Habilitado,
#programa_Tiene_Vigencia {
    font-size: 20px;
    margin-left: -30px;
}

.form-check-input {
    background-color: #fa5720;
    border-color: #fa5720;
}

.form-check-input:checked {
    background-color: #2ed28a;
    border-color: #2ed28a;
}
label {
    color: #01295F;
    font-family: 'Inter-SemiBold';
}
.fw-bold {
    color: #0b1f44;
}
.card-verde {
    background: linear-gradient(to right, #27936e, #2ed28a);
}
.btn-outline-success {
    border-color: #2ed28a !important;
    color: #2ed28a !important;
    background: #fff;
}
.btn-outline-success:hover {
    background-color: #2ed28a !important;
    border-color: #2ed28a !important;
    color: #fff !important;
}

.btn-outline-warning {
    border-color: #fa5720 !important;
    color: #fa5720 !important;
    background: #fff;
}
.btn-outline-warning:hover {
    background-color: #fa5720 !important;
    border-color: #fa5720 !important;
    color: #fff !important;
}
.bm-burger-button {
    top: 15px !important;
}
.Swiperprincipal .swiper-button-prev:after,
.swiper-container-rtl .swiper-button-next:after {
    content: '‹' !important;
    color: aliceblue;
}
.Swiperprincipal .swiper-button-next:after,
.swiper-container-rtl .swiper-button-prev:after {
    content: '›' !important;
    color: aliceblue;
}
.mySwiperPromotions .swiper-wrapper {
    padding-top: 30px;
    padding-bottom: 30px;
}
.SwiperCanjesE .imgCanje {
    width: auto;
    display: unset;
    height: 200px;
    font-size: 2.5vw;
}
.SwiperCanjesT .imgCanje {
    width: 150px;
    height: 150px;
    margin-left: 40%;
    font-size: 2.5vw;
}
.SwiperCanjesM .imgCanje {
    width: 100px;
    height: 100px;
    margin-left: 35%;
    font-size: 3vw;
}

.SwiperCanjesE h4 {
    padding-top: 14px;
}
.SwiperCanjesT h4 {
    padding-top: 14px;
}
.SwiperCanjesM h4 {
    padding-top: 14px;
}

.SwiperCanjesE p {
    font-size: 25px;
    padding-bottom: 15px;
}
.SwiperCanjesT p {
    font-size: 18px;
    padding-bottom: 15px;
}
.SwiperCanjesM p {
    font-size: 18px;
    padding-bottom: 15px;
}

.SwiperCanjesE .imgCanjemoneda {
    width: 80px;
    height: 80px;
    float: left;
    position: absolute;
    left: 5%;
}
.SwiperCanjesE .imgCanjetick {
    width: 70px;
    height: 35px;
    position: absolute;
    right: 5%;
    top: 80px;
}
.SwiperCanjesE .imgCanjetrofeo {
    width: 60px;
    height: 50px;
    position: absolute;
    bottom: 0px;
    left: 20%;
}

.SwiperCanjesT .imgCanjemoneda {
    width: 50px;
    height: 50px;
    float: left;
    position: absolute;
    left: 5%;
}
.SwiperCanjesT .imgCanjetick {
    width: 60px;
    height: 25px;
    position: absolute;
    right: 1%;
    top: 80px;
}
.SwiperCanjesT .imgCanjetrofeo {
    width: 50px;
    height: 40px;
    position: absolute;
    bottom: 0px;
    left: 20%;
}

.SwiperCanjesM .imgCanjemoneda {
    width: 50px;
    height: 50px;
    float: left;
    position: absolute;
    left: 5%;
}
.SwiperCanjesM .imgCanjetick {
    width: 60px;
    height: 25px;
    position: absolute;
    right: 1%;
    top: 80px;
}
.SwiperCanjesM .imgCanjetrofeo {
    width: 50px;
    height: 40px;
    position: absolute;
    bottom: 0px;
    left: 20%;
}

@media (max-width: 800px) and (min-width: 520px) {
    .SwiperCanjesT .imgCanjetrofeo,
    .SwiperCanjesT .imgCanjetick,
    .SwiperCanjesT .imgCanjemoneda {
        display: none;
    }
}

.swiper-button-prev:after,
.swiper-container-rtl .swiper-button-next:after {
    content: '‹' !important;
    color: #007aff;
}
.swiper-button-next:after,
.swiper-container-rtl .swiper-button-prev:after {
    content: '›' !important;
    color: #007aff;
}

.cardTotalPuntos span,
.cardTotalPuntos p,
.cardImpoteTotal span,
.cardImpoteTotal p,
.cardUsuarios span,
.cardUsuarios p,
.cardReferidos span,
.cardReferidos p {
    color: #ffffff !important;
}
.cardTotalPuntos {
    background: linear-gradient(to right, #6850ca, #9782e9);
}
.cardImpoteTotal {
    background: linear-gradient(to right, #27926e, #47d798);
}

.cardUsuarios {
    background: linear-gradient(to right, #fa5720, #f7a603);
}

.cardReferidos {
    background: linear-gradient(to right, #5a73e8, #44c2fa);
}

@media (max-width: 800px) {
    .btn-carrousel-home-index {
        background: rgb(24, 154, 214);
        color: rgb(237, 247, 251);
        border: none;
        /*font-size: 12px;*/
        padding: 7px 15px 7px 15px;
        border-radius: 30px;
        pointer-events: all;
        min-width: 154px;
    }
    .img-fotter-app {
        height: 10vw !important;
    }
    .header-home-index a,
    .header-home-index label {
        font-size: 12px;
        padding: 0px;
    }
    .header-home-index .col-5 {
        margin-right: 155px !important;
        z-index: 10 !important;
    }
    .derechos-reservados {
        bottom: 0px !important;
        position: relative !important;
    }
}
@media (min-width: 800px) {
    .btn-carrousel-home-index {
        background: rgb(24, 154, 214);
        color: rgb(237, 247, 251);
        border: none;
        /*font-size: 16px;*/
        padding: 1.5vh 30px 1.5vh 30px;
        border-radius: 10px;
        pointer-events: all;
        min-width: 180px;
    }
}

@media (max-width: 650px) {
    .header-home-index label {
        font-size: 12px;
        line-height: 10px;
    }
    .header-home-index img {
        height: 2.5vw !important;
        margin-top: 25px !important;
        margin-left: 5px !important;
        max-width: initial !important;
    }
    .fyttsa-club {
        height: 15px !important;
    }
}

.derechos-reservados p {
    font-size: 10px;
}

@media (max-width: 650px) {
    .h1-suscripcion-cantidad {
        font-size: 12vw !important;
    }
    .h1-suscripcion-puntos {
        font-size: 8vw !important;
    }

    .derechos-reservados p {
        font-size: 8px;
    }
    .derechos-reservados {
        bottom: 0px !important;
    }

    .SwiperCliente .swiper-pagination {
        bottom: 0px !important;
    }
}

@media (max-width: 500px) {
    .footer h3 {
        font-size: 16px;
    }
    .footer h6 {
        font-size: 0.5rem !important;
    }
    .footer h1 {
        font-size: 21px;
    }
    .footer p {
        font-size: 9px;
    }
    .footer label {
        font-size: 10px;
    }

    .suscripcion h1 {
        font-size: 13px;
    }

    .suscripcion br {
        display: none;
    }
    .suscripcion input {
        font-size: 12px;
        margin-bottom: 10px;
    }
}

.swiper-pagination-bullet {
    width: 15px;
    height: 15px;
    display: inline-block;
    border-radius: 50%;
    background: #aab0c1;
    opacity: 0.8;
}
.swiper-pagination-bullet-active {
    opacity: 1;
    background: var(--swiper-pagination-color, var(--swiper-theme-color));
}

.swiper-container-horizontal > .swiper-pagination-bullets,
.swiper-pagination-custom,
.swiper-pagination-fraction {
    bottom: 0px;
}

.header-div {
    z-index: 10;
    position: relative;
}
.Swiperprincipal {
    width: 100%;
    height: 100vh;
    margin-top: -70px;
}
.Swiperprincipal .swiper-slide {
    padding-top: 70px;
}

.SwiperCliente {
    margin-top: 15px;
}

.containerImgSlider {
    height: 100%;
    background-repeat: no-repeat !important;
    background-position: center !important;
    background-size: cover !important;
    background-attachment: fixed;
}

.containerImgSliderMovil {
    height: 100%;
    background-repeat: no-repeat !important;
    background-position: center !important;
    background-size: contain !important;
    background-attachment: fixed;
    z-index: 100;
}
.btn-carrousel-home-index {
    font-family: 'Inter-Bold';
}
/*
@media (min-width: 2000px) {
    .btn-carrousel-home-index {
        font-size: 2.5rem;
        padding: 30px 30px 30px 30px;
        min-width: 400px;
    }

    .login .img-fyt {
        width: 400px !important;
    }
    .login h2 {
        font-size: 60px;
    }
    .login p,
    .login input,
    .login a,
    .login small,
    .login select {
        font-size: 30px !important;
    }
    .login option {
        font-size: 18px !important;
    }
    .login .derechos-reservados p {
        font-size: 20px;
    }
    .footer p {
        font-size: 20px;
    }
    .suscripcion input,
    .suscripcion button,
    .suscripcion label {
        font-size: 25px;
    }

    .header-home-index p {
        font-size: 17px;
    }
    .header-home-index a {
        font-size: 16px;
    }
}*/

@media (max-width: 700px) {
    .header-home-index a,
    .header-home-index label,
    .row-home-cliente a,
    .row-home-cliente label {
        font-size: 10px;
        padding: 0px;
    }

    .header-home-index .col-2,
    .header-home-index .col-sm-2 {
        max-width: 119px !important;
        min-width: 119px !important;
        right: 0px !important;
    }
    .header-home-index .col-5 {
        margin-right: 110px !important;
        z-index: 10 !important;
    }
}

@media (max-width: 500px) {
    .header-home-index .col-5 {
        margin-right: 95px !important;
        z-index: 10 !important;
    }
    .header-home-index .col-5 a,
    .header-home-index .col-5 label {
        font-size: 8px;
        padding: 0px;
    }
    .header-div .col-8 {
        max-width: 180px !important;
    }
}
.modal-close {
    z-index: 100;
}
.login .card-footer {
    background: transparent !important;
}
.row-home-cliente {
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    display: flex;
}
.row-home-cliente .login {
    display: flex;
    align-items: center;
}

.row-home-cliente .imagen {
    display: flex;
    align-items: center;
}

.row-home-cliente .col-3 img {
    width: 100%;
    max-width: 100%;
    height: auto;
    max-height: 60px;
}

@media (max-width: 575px) {
    .row-home-cliente .col-6 div {
        max-width: 160px;
        margin: 0px auto;
    }
    .row-home-cliente .col-6 label,
    .row-home-cliente .col-6 a {
        font-size: 11px;
        line-height: 20px;
    }
    .row-home-cliente .col-3 {
        padding-left: 5px;
        padding-right: 5px;
    }
}
@media (min-width: 576px) {
    .row-home-cliente .col-sm-6 div {
        max-width: 350px;
        margin: 0px auto;
        margin-right: 0px;
        margin-top: 10px;
    }
    .row-home-cliente .login a {
        display: table-cell;
        vertical-align: middle;
        width: 100%;
    }
}
@media (min-width: 768px) {
    .row-home-cliente .col-3 img {
        max-width: 80%;
        margin-left: 10%;
    }

    .row-home-cliente .col-md-6 div {
        font-size: 14px;
    }
    .row-home-cliente .col-md-6 div {
        max-width: 350px;
        margin: 0px auto;
        margin-right: 0px;
        margin-top: 10px;
    }
    .row-home-cliente .login a {
        display: table-cell;
        vertical-align: middle;
        width: 100%;
    }
    @media (min-width: 2000px) {
        .row-home-cliente .col-md-6 div {
            max-width: 450px;
            font-size: 21px;
        }
    }
}

table > thead > tr {
    background: #232ed1;
    color: white;
}
table > tbody > tr > td {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;

    color: #01295f;
}

#root {
    height: 100%;
}

.swiper-slide:hover {
    background-color: #232ed1;
    color: #fff !important;
}
.swiper-slide:hover span,
.swiper-slide:hover p {
    color: #fff !important;
}
svg.active-li {
    color: #232ed1;
}
svg.inactive-li {
    color: #c3c5d2;
}
.swiper-slide:hover .inactive-li,
.swiper-slide:hover .active-li {
    color: #fff !important;
}

.swiper-slide:hover .precio-inactive {
    background: #3a44dc;
}
.precio-inactive {
    background: #f4f7ff;
}

.swiper-slide:hover .boton-comprar-paquete svg path,
.swiper-slide:hover .boton-comprar-paquete {
    background: rgba(255, 255, 255, 0);
    border-color: #ffffff;
    fill: #ffffff;
    color: #ffffff;
}

.swiper-container {
    padding-bottom: 30px;
}

.text-right {
    text-align: right;
}
.text-left {
    text-align: left;
}
input[type='number'],
input[type='text'],
input[type='file'],
input[type='password'],
select {
    background-color: #f4f7ff !important;
}

/* SIDEBAR STYLES */
.sidebar {
    display: grid;
    grid-template-columns: repeat(2, min-content);
}

.sidebar-logo {
    width: 85%;
    height: 35px;
    margin: 0 auto;
}

.icon-menu-sidebar {
    width: 25px !important;
}

.submenu-sidebar {
    display: grid !important;
    grid-template-rows: min-content auto min-content;
    height: 100%;
    min-height: 880px;
}

.boton-crear-factura {
    background: #232ed1;
    border-radius: 8px;
    border: none;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #ffffff;
    height: 56px;
    width: 237px;
    position: relative;
    padding-left: 20px;
}

.icon-boton {
    position: absolute;
    top: 0;
    left: 0;
    margin: 17px 0px 0px 28px;
    width: 22px;
    height: 20px;
}

.footer-sidebar {
    display: grid;
    grid-template-rows: repeat(3, max-content);
    align-items: center;
    justify-items: center;
    align-self: end;
}

.img-footer-sidebar {
    width: 176px;
    height: 155px;
    margin-top: 20px;
    margin-bottom: 38px;
}

.mejorar-plan,
.text-footer-sidebar {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    margin-bottom: 20px;
}

.mejorar-plan {
    display: grid;
    grid-template-columns: repeat(2, max-content);
    gap: 15px;
    height: fit-content;
    color: #01295f;
    text-decoration: none;
    margin-bottom: 35px;
    justify-content: center;
}
.mejorar-plan:hover {
    color: #01295f;
}

.dom-sections {
    height: 100%;
    min-height: 850px;
    display: grid;
    grid-template-columns: 250px auto;
}

.submenu-sidebar-scroll {
    overflow-y: auto;
    background: #f4f7ff;
}
.menu-sidebar-scroll {
    overflow-y: auto;
}

.menu-sidebar-scroll::-webkit-scrollbar,
.submenu-sidebar-scroll::-webkit-scrollbar {
    display: none;
}

@media screen and (max-width: 1300px) {
    .dom-sections {
        grid-template-columns: 0px auto;
    }
}

/* TOPBAR STYLES */
.topbar {
    height: 172px; /*96px*/
    background: rgb(238 233 242);/*#fff;*/
    width: 100%;
    justify-items: center;
    padding: 0px 36px;
}

@media screen and (min-width: 769px){
    .topbar {
        height: 55px; /*96px*/
        background: rgb(238 233 242);/*#fff;*/
        width: 100%;
        display: flex;
        align-items: center;
        justify-items: center;
        padding: 0px 9p 0 36px;
    }
}

@media screen and (min-width: 980px){
    .topbar {
        height: 55px; /*96px*/
        background: rgb(238 233 242);/*#fff;*/
        width: 100%;
        display: grid;
        grid-template-columns: 260px max-content 151px max-content;
        gap: 3px;
        align-items: center;
        justify-content: space-between;
    }
}
.input-search {
    height: 52px;
    width: 100%;
    padding-left: 40px;
    background: #f4f7ff;
    border: none;
    border-radius: 4px;
    outline: none;
}
.input-search::placeholder {
    font-family: 'Inter';
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #c3c5d2;
}

.title2{
    color: gray;
}
/*
input[type=text]{
    border: none;
}

.form-control:focus{
    outline-color: orangered;
}
*/
.icon-search {
    position: absolute;
    top: 0;
    left: 0;
    margin: 11px 12px;
}

.icon-topbar {
    width: 18px;
    height: 21px;
}

.sellos-nuevos {
    height: 42px;
    min-width: 92px;
    background: #06BCC1;
    border-radius: 25px;
    display: grid;
    align-items: center;
    justify-items: center;
    margin-left: 5px;
}
.sellos-text {
    font-family: 'Inter-SemiBold';
    font-size: 12px;
    line-height: 15px;
}
.sellos-disponibles {
    min-width: 185px;
    height: 42px;
    background: #232ED1;
    border-radius: 25px;
    display: grid;
    grid-template-columns: repeat(2, max-content);
    align-items: center;
    justify-items: center;
    gap: 10px;
}

.sellos-numero {
    width: 52px;
    height: 25px;
    background: #ffffff;
    border-radius: 25px;
    display: grid;
    align-items: center;
    justify-items: center;
    margin-left: 8px;
}

.avatar-user {
    width: 54px;
    height: 54px;
    border-radius: 30px;
}
.username {
    font-family: 'Inter';
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: #01295f;
}
.user-rol {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #c3c5d2;
}

/* PLANES Y PRECIOS STYLES */

.tarjeta-paquete {
    background-color: #ffffff;
    border: 1px solid #f0f0f0;
    border-radius: 8px;
    padding: 75px 24px 60px;
    transition: all 100ms ease-in-out;
    max-width: 360px;
}
.paquete {
    font-family: 'Inter-SemiBold';
    font-size: 14px;
    line-height: 17px;
    color: #232ed1;
    margin-bottom: 10px;
}
.categoria,
.nombre-paquete {
    font-family: 'Poppins';
    font-size: 28px;
    line-height: 32px;
    color: #01295f;
}

.cantidad-facturas {
    display: grid;
    grid-template-columns: repeat(2, max-content);
    height: 36px;
    gap: 5px;
    margin-bottom: 20px;
}

.numero-facturas {
    font-family: 'Inter';
    font-weight: 600;
    font-size: 36px;
    line-height: 44px;
    color: #01295f;
}
.facturas {
    font-family: 'Inter';
    font-size: 14px;
    line-height: 17px;
    color: #01295f;
    padding-top: 7px;
}
.categoria {
    font-size: 18px;
    line-height: 18px;
    margin-bottom: 10px;
}

.listado-caracteristicas {
    display: grid;
    grid-template-rows: repeat(7, minmax(44px, auto));
}
.caracteristica {
    border-radius: 8px;
    display: grid;
    grid-template-columns: max-content auto;
    gap: 12px;
    align-items: center;
    padding-left: 12px;
}
.texto-caracteristica {
    font-family: 'Inter';
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #01295f;
}

.boton-comprar-paquete {
    height: 44px;
    border: 1px solid #232ed1;
    border-radius: 8px;
    width: 100%;
    font-family: 'Inter';
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #232ed1;
    background: #ffffff;
    display: grid;
    grid-template-columns: auto auto;
    align-items: center;
    justify-items: center;
    padding: 2% 18%;
    margin-top: 20px;
}

.boton-comprar-paquete svg {
    justify-self: right;
}

.boton-comprar-paquete:hover svg path,
.boton-comprar-paquete:hover {
    border-color: #3fd77b !important;
    color: #3fd77b !important;
    fill: #3fd77b !important;
    transition: all 250ms ease-out;
}

/* DASHBOARD STYLES */
.dashboard {
    display: flex;
    width: 100%;
}

.saludo {
    margin-bottom: 19px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 29px;
    color: #757783;
}

.bar-right {
    background-color: #f4f7ff;
    padding: 19px 25px 25px;
    height: 100%;
    width: 27%;
}

.status-uds {
    display: grid;
    grid-template-columns: auto max-content;
    grid-template-rows: repeat(3, max-content);
    margin-bottom: 70px;
}

.text-uds {
    font-family: 'Inter';
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
}

.encabezado-tabla,
.integrar-plataformas p,
.status-ultimo-mes p,
.subtext-uds {
    font-family: 'Inter';
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #c3c5d2;
}

.barra {
    grid-column: 1 / -1;
    margin-top: 15px;
}

.status-ultimo-mes {
    display: grid;
    grid-template-columns: max-content max-content auto;
    grid-template-rows: repeat(5, max-content);
    align-items: center;
    /*gap: 25px 13px;*/
    margin-bottom: 40px;
    padding: 6px;
}

.status-ultimo-mes img {
    width: 40px;
    height: 40px;
}
.status-ultimo-mes span {
    font-family: 'Inter-SemiBold';
    font-size: 16px;
    line-height: 19px;
    color: #232ed1;
    justify-self: center;
    padding-right: 10px;
}

.bold {
    font-weight: 500 !important;
    color: #01295f !important;
    margin-bottom: 6px;
}

.integrar-plataformas {
    background: #007eff;
    box-shadow: 8px 8px 28px rgba(35, 46, 209, 0.16);
    border-radius: 8px;
    display: grid;
    grid-template-rows: repeat(7, max-content);
    justify-items: center;
    gap: 17px;
    padding: 19px 0px 24px;
}

.integrar-plataformas button {
    background: #232ed1;
    border-radius: 8px;
    display: grid;
    grid-template-columns: auto max-content;
    border: none;
    height: 44px;
    min-width: 142px;
    font-family: 'Inter';
    font-weight: 400 !important;
    font-size: 14px;
    line-height: 17px;
    color: #fafafa;
    justify-items: center;
    align-items: center;
    padding: 0px 20px;
}
.integrar-plataformas span {
    font-family: 'Inter-SemiBold';
    font-size: 16px;
    line-height: 19px;
    color: #232ed1;
}

.integrar-plataformas p {
    padding: 0px 16px;
    text-align: center;
}

.logos {
    height: 35px;
}

.contenedor-tabla {
    background: #ffffff;
    border: 1px solid #f0f0f0;
    box-shadow: 8px 8px 36px rgba(30, 56, 136, 0.06);
    border-radius: 8px;
    padding: 20px 25px;
}

.header-tabla {
    display: grid;
    grid-template-columns: auto max-content;
    align-items: center;
    gap: 20px;
    margin-bottom: 18px;
}

.titulo-tabla {
    font-family: 'Inter';
    font-weight: 500;
    font-size: 24px;
    line-height: 29px;
    color: #01295f;
}
.ver-todo:hover,
.ver-todo {
    font-family: 'Inter';
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #232ed1;
    text-decoration: none;
    cursor: pointer;
}

.tabla {
    display: inline-block;
    width: 100%;
}

.encabezado-tabla {
    font-weight: 500 !important;
}

.doble-columna {
    grid-column: span 2;
}

.file-type {
    width: 40px;
    height: 40px;
    margin-right: 18px;
}

.text-tabla {
    font-family:'Inter';
    font-size: 12px;
    line-height: 15px;
    color: #01295f;
}

.tres-puntos:hover,
.tres-puntos {
    font-family:'Inter-Bold';
    font-size: 30px;
    line-height: 0px;
    color: #232ED1;
    align-self: center;
    justify-self: center;
    padding-bottom: 18px;
    text-decoration: none;
    cursor: pointer;
    margin-left: 45px;
}
/*
.facturar input,.facturar select,.facturar button,a{
    font-size: .80rem !important;
}*/
/*.accordion-body {
    padding: 0rem 0rem;
}*/
label{
    padding-top: 10px;
}
.topbar{
    box-shadow: 2px 5px 10px 5px rgb(0 0 0 / 10%);
}

/************Diseño nuevo figma******************/
h2{
    color: #01295F;
}
span{
    color: #01295F;
}
button,a{
     font-size: 14px !important;
}
.card-header
{
    background-color: #fff;
}
#react-burger-menu-btn{
    color: transparent;
}
.bm-burger-button
{
    left: 5px !important;
}


@media screen and (max-width: 1300px) {
.datetable-scroll{
    overflow-x: auto;
    max-width: calc(95vw);
}
}

@media screen and (min-width: 1300px) {
    .datetable-scroll{
        overflow-x: auto;
        max-width: calc(100vw - 295px);
    }
    }
/************Fin diseño nuevo figma******************/
.td-url{
    max-width: 200px;
}
.td-button{
    max-width: 150px;
}




/*  ajuste de los tamaños*/
@media screen and (min-width: 1900px) {
h2{
    font-size: 2.6vw;
}
h4{
    font-size: 2.5vh;
}
p,label,button,input,a,.form-control,.btn,::placeholder{
    font-size: 2.2vh;
}
a{
    font-size: 2.2vh;
}
.form-control{
    padding: 1vh;
}

.class-ticket label
{
    font-size: 2.6vh !important;
}
.dom-sections h2{
    font-size: 1.1vw ;
}
.dom-sections h4{
    font-size: 1vw;
}
.dom-sections p,
.dom-sections label,
.dom-sections button,
.dom-sections input, 
.dom-sections a, 
.dom-sections .form-control,
.dom-sections .btn,
.dom-sections ::placeholder,
.dom-sections select,
.react-select-placeholder{
    font-size: 1.2vh;
}

.dom-sections a{
    font-size: 1.2vh;
}
.dom-sections .form-control{
    padding: .2vh;
}

.dom-sections .class-ticket label
{
    font-size: 1.6vh;
}
}

@media screen and (max-width: 800px) {
.input-icon-form img{
    width: 25px !important;
    padding: 10px 5px !important;
}
}
/*

@media screen and (max-width: 1300px) {
    h4{
        font-size: 1.5vh;
    }
    label{
        font-size: 1.6vh;
    }
}*/
.fecha-table{
    width: 100px;
}
table img{
    max-width: 50px;
    width: 25px;
    display: inherit;
}
  .card-body{
  margin-top: -1.7rem !important;
  }

  #table-js button,#table-js a{
    width: 100%;
    text-align: left;
  }
  #table-js li:hover{
    background-color: #d2e1e9;
  }
  .dataTables_scrollBody{
    min-height: 350px;
  }

  .dataTables_scroll{
    padding-top: 15px;
  }
    .select-filter{
        border: 1px solid #aaa;
        border-radius: 3px;
        padding: 5px;
        background-color: transparent;
        padding: 4px;
    }

    td.details-control {
        background: url('https://datatables.net/examples/resources/details_open.png') no-repeat center center;
        cursor: pointer;
    }
    tr.details td.details-control {
        background: url('https://datatables.net/examples/resources/details_close.png') no-repeat center center;
    }

.text-gray {
    margin-bottom: 3px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 29px;
    color: #757783;
}
.w-40{
    margin-left: 1%;
    width: 39%;
}
.w-60{
    width: 60%;
}
.text-gray-bold {
    font-style: bold;
    font-weight: 500;
    font-size: 21px;
    line-height: 29px;
    color: #757783;
}
.cardCustom{
    border: 0.5px solid #EAEBEC;
    box-shadow: 8px 8px 36px rgba(30, 56, 136, 0.06);
}
.cardCustom div{
    padding: 1px 4px;
}
.borderTop{
    border-top: 1px solid #EAEBEC;
}
.text-gray-right {
    text-align: right;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 29px;
    color: #757783;
}
.text-gray-right p{
    width: 50%;
}
.p-block{
    color: #1d1f2c;
    line-height: 2;
    font-size: 14px;
    margin-top: 31px;
    width: 50%;
}
.selectCustom{
    border: 3px solid #c3c5d2;
    color: #757783;
    font-size: 15px;
}
.selectCustom:focus-visible{
    border: 3px solid #bdbeb3;
}
.selectCustomTwo{
    border: 1px solid #aaa;
    border-radius: 3px;
    padding: 5px;
}
.blockCenter{
    margin-left: 1%;
    width: 72%;
}
.dobleColumna{
    text-align: center;
    width: 40%;
}
.simpleColumna{
    text-align: center;
    width: 20%;
}
.tabla span{
    align-self: center;
}
.align-right{
    margin-left: auto;
}
.blockAyuda{
    background-color: #f1f4f5;
    padding: 21px 33px;
}
.blockAyuda >h1{
    color: #464a61;
    font-size: 18px;
}
.blockAyuda .block{
    background-color: white;
    padding: 18px;
}
.blockAyuda .block h1{
    color: #757783;
    font-size: 24px;
    font-weight: bold;
}
.blockAyuda .block .collapsible{
    border: 1px solid #eaebe5;
    box-shadow: 2px 2px 2px rgba(30, 56, 136, 0.2);
    margin: 12px 0;
    padding: 12px;
}
.blockAyuda .block .collapsible .titulo{
    color: #2aa3dd;
    cursor: pointer;
    font-size: 18px;
    font-weight: 500;
}
.blockAyuda .block .collapsible .contenido{
    color: #757783;
}
.blockAyuda .block .collapsible .image{
    margin: 1%;
    width: auto;
}
.blockAyuda .block .collapsible .contenido p{
    padding: 9px;
}
.t-row{
    margin: 9px 3px;
}
.link-pointer{
    color: #232ED1;
    cursor: pointer;
    text-decoration: none;
}
.link-pointer:hover{
    text-decoration: underline;
}
.min-180{
    min-width: 180px;
}

@media screen and (max-width: 640px)
{
    .dataTables_wrapper .dataTables_filter{
        text-align: left;
    }
    .dataTables_wrapper .dataTables_length{
        text-align: left;
    }
    .dataTables_wrapper .dataTables_filter>label{
        display: flex;
    }
    .dataTables_wrapper .dataTables_filter>label input{
        width: 100%;
    }
}
@media screen and (min-width: 768px)
{
    .wmd-25{
        width: 25%;
    }
    .dataTables_length{
        display: flex;
    }
}
.dataTables_length{
    >label{
        display: flex;
    }
    >label select{
        margin: 1%;
        width: 100%;
    }
}
.dataTable th, .dataTable td {
    max-width: 400px;
    min-width: 100px;
    white-space: initial !important;
}
.swal-wide-size .swal2-html-container{
    font-size: .8rem;
}
.ocultar{
 display: none;
}

.btn-verComplemento img{
    max-width: 50px !important;
    width: 40px !important;
    display: inherit !important;
}
