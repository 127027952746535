.contentEst{
    padding: 33px;
}
.blockNumbers{
    display: flex;
    padding: 9px;
}
.colorDarkBlue{
    color: #03256C;
    font-size: 30px;
}
.itemNumber{
    border-left: 4px solid #EAEBEC;
    margin-right: 96px;
    padding-left: 9px;
}
.positionRelative{
    position: relative;
}
.spanBlue{
    color: #06BCC1;
    display: inline-block;
    margin-left: 3px;
    position: absolute;
    top: -3px;
}
.divider{
    border-bottom: 1px solid #D6D6D9;
    display: inline-block;
    height: 1px;
    margin: 24px 0 18px;
    width: 99%;
}
.cardGraph{
    background: #FFFFFF;
    border: 0.5px solid #EAEBEC;
    box-shadow: 8px 8px 36px rgba(30, 56, 136, 0.06);
    border-radius: 8px;
    padding: 33px;
}
.top-10{
    top: 10%;
}
.row-flex{
    display: flex;
}
.space-25{
    width: 25%;
}
.space-50{
    width: 50%;
}
.row-flex p{
    font-size: 14px;
    padding: 15px 12px;
}
.font-weight-500{
    font-weight: 500;
}